import * as THREE from 'three';
import { mergeBufferGeometries } from "three/examples/jsm/utils/BufferGeometryUtils";
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
const GUI = require( 'three/examples/jsm/libs/lil-gui.module.min.js' ).GUI;
// const Stats = require( 'three/examples/js/libs/stats.min.js');

// const GLTFExporter = require('three-gltf-exporter');
const OBJExporter = require( 'three/examples/jsm/exporters/OBJExporter.js').OBJExporter;
const GLTFExporter = require('./GLTFExporter.js').GLTFExporter;
export  class ThreeDViewer {

private _resizeTimer : any ;
private _scene : any;
private _camera  : any;
private _renderer  : any;
private _controls  : any;
private _gui  : any;
private _loader : any;
private _helper : any; 
// private _stats : any;
private _mesh : any ;
constructor ( conf :any ) {

            this._scene = new THREE.Scene();
	        // カメラ初期化
			this._camera = new THREE.PerspectiveCamera( conf.camera.fov , conf.view.clientWidth/ conf.view.clientHeight, 1, 1000 );
            this._camera.position.set(0, 0, 0);
            this._camera.lookAt(new THREE.Vector3(0,0,0));
            
            // ローダ初期化等
            this._helper = new THREE.AxesHelper(50);
            this._scene.add(this._helper);
            this._loader = new PCDLoader();
           
            const grid = new THREE.GridHelper( 1000,  1000, 0x888888, 0x888888 );
			 //	grid.material.opacity = 0.2;
		     // grid.material.transparent = true;
				this._scene.add( grid );
        
           if(this._gui){
              
              this._gui.reset();
           }
            this._gui = new GUI();
             this._gui.title("メニュー");
            
         //  this._gui.hide();
         //   exportFolder.toggleHide();
            this._gui.open( this._gui._closed );
            //this._gui.open();
			
			this._renderer = new THREE.WebGLRenderer();
			// this._renderer.setClearColor(0xffffff);
			this._renderer.setClearColor( 0x000000, 0 );
			this._renderer.setSize( conf.view.clientWidth, conf.view.clientHeight );
			this._renderer.setPixelRatio(window.devicePixelRatio);
			conf.view.appendChild( this._renderer.domElement );

			this._camera.position.z = 5;
		
		    //視点のコントローラ	
			this._controls = new OrbitControls( this._camera, this._renderer.domElement );
            // If you use the animate method, delete this function
            //controls.addEventListener( 'change', render );
            // Whether there is inertia in the meaning of damping or rotation when the animation is recycled
            this._controls.enableDamping = true;
            //Dynamic damping coefficient is the mouse drag rotation sensitivity
            //controls.dampingFactor = 0.25;
            //Can I zoom
            this._controls.enableZoom = true;
            //Auto rotate or not
           // controls.autoRotate = true;
           // controls.autoRotateSpeed = 0.5;
            //Set the maximum distance between the camera and the origin
            this._controls.minDistance  = 1;
            //Set the maximum distance between the camera and the origin
            this._controls.maxDistance  = 50;
            //Enable right drag
            this._controls.enablePan = true;
          
              window.removeEventListener("resize", this.resize.bind(this) ); 
              window.addEventListener("resize", this.resize.bind(this) );
          // stats
         // this._stats = new Stats();
         // conf.view.appendChild(this._stats.dom);
	      this.animate(); 
	}
    
    resize(this :any ){
        
        this. _resizeTimer = setTimeout( ()=>{
        const width = window.innerWidth;
        const height = window.innerHeight;
        this._renderer.setPixelRatio(window.devicePixelRatio);
        this._renderer.setSize(width, height);
        this._camera.aspect = width / height;
        this._camera.updateProjectionMatrix();
        } , 1000 );
  } 
    
    addExports(this:any){

           this.addFolder( 
           "エクスポート", [
                { func : this.exportOBJ.bind(this)  , title : "OBJ" } ,
                { func : this.exportGLTF.bind(this)  , title : "GLTF" } ,
                { func : this.exportGLB.bind(this)  , title : "GLB" }   
            ] );
	    
	}
    addFolder(this:any , name :string , options :any){
        
        const folder = this._gui.addFolder( name );
       
       for(let option = options.shift() ; option ;  option = options.shift()  ) { 
            
            const op :any  =  {}  ;
            op [  option.func.name ] = option.func ;
            folder.add(  op  , option.func.name  ) . name( option.title )  ;
        }
        folder.open(folder._closed);
    }
    
    addGUI  ( this:any , option:any ){
      
        const op :any  =  {}  ;
        op [  option.func.name ] = option.func ;
        this._gui.add(  op  , option.func.name  ) . name( option.title )  ;
        
    }
    
    async loadFile ( url :any , coor = 'xyz' ) {
         return new Promise ( (suc :any ,err:any)=>{
            this._loader.load(
             url ,
             ( points :any )=> {
               
               /*  
              if(coor==='yxz'){
                const pa = points.geometry.attributes["position"].array; 
                
                 let tmpA =[];
                const pArr = [] ;
                for( let i=0 ; i <  pa.length;i++){
                     tmpA.push(pa[i]);
                     if( i !==0 && (i %3)===0 ){
                         pArr.push( tmpA[0],  tmpA[1], tmpA[2]); 
                         tmpA=[];
                     }
                
                }
             
              //  console.log(points);
               
                const geom = new THREE.BufferGeometry();
                 geom.setAttribute('position', new THREE.Float32BufferAttribute(pa, 3));

                // const mate = points.material;
                   const mate = new THREE.PointsMaterial({
            // 一つ一つのサイズ
            size: 0.01,
            // 色
            color: points.material.color ,
          });

                const ps = new THREE.Points(geom,mate);
                  this._scene.add(  ps );
        
              }else{
            */
                   this._scene.add(  points );
      
            
              this.render();
              suc(true);
        },
    // called when loading is in progresses
        ( xhr:any )=> {

            console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
        },
    // called when loading has errors
     ( e:any )=> {
            console.log( 'An error happened' );
            err(e);
        });
      });
    }
    
    render () {
        this._renderer.render( this._scene, this._camera );
    }

    animate( this:any ){
        this.render();
        //Update performance plug-ins
       //  this._stats.update();
        this._controls.update();
    
        requestAnimationFrame(this.animate.bind(this));     
    }
    exportOBJ( this:any ){
    
        const exporter = new OBJExporter();
        this.saveString( exporter.parse( this._scene  ), 'test.obj');
    }
    
    exportGLTF( this:any ){
        console.log(GLTFExporter);
        const exporter = new GLTFExporter();
         exporter.parse( this._scene , (g:any)=>{this.saveArrayBuffer ( g, 'test.gltf');  } , { binary: true} );      
    }
   
    exportGLB( this:any ){
        const exporter = new GLTFExporter();
        this.saveString( exporter.parse( this._scene  ), 'test.glb');
    }
     saveString( text :any , filename :any ) {

				this.save( new Blob( [ text ], { type: 'text/plain' } ), filename );

     }
     
     saveArrayBuffer( buffer:any , filename :any ) {

		this.save( new Blob( [ buffer ], { type: 'application/octet-stream' } ), filename );

	}
	
	save( blob:any , filename :any ) {

            const link = document.createElement( 'a' );
			link.style.display = 'none';
			window.document.body.appendChild( link );
            link.href = URL.createObjectURL( blob );
			link.download = filename;
			link.click();
		}
   getScene(){
       return this._scene;
   }
}
