<template>
    <div>
      
      <div  style="position:fixed; left: 0; top: 0;width:100%; height:100%;" id="three"></div>

    </div>
</template>

<script >

import {ThreeDViewer} from   '../../modules/app/three/ThreeDViewer';

export default {
  name: "PCDViewer" ,
  data()  {
          return {
              threeDViewer  : undefined,
         }  ;
  }

  , beforeMounted : ()=>{
      
  }      
  , mounted :async function( ){  

             this.threeDViewer = new ThreeDViewer(
        {
            camera  : { fov : 75 } ,
            view : window.document.getElementById('three')
        }
     );
     
      this.threeDViewer.addExports();
     
       window.showPCD = async (   data  ) => {
        
            let file  = window.URL.createObjectURL(data);
            //alert(file.length);
            await this.threeDViewer.loadFile( file );  
        
        };
          
    }
  ,methods:{         
    }
}

</script>

<style>

body{
    
    background-color:transparent !important;
}

#three{
    background:black;
}

@media (max-width: 150px) {
    .lil-gui{
        display:none !important;
    }
    #three{
        background:rgba(0,0,0,0.7);
    }
}

@media (max-height: 300px) {
    .lil-gui{
        
        display:none !important;
    }
      #three{
          
        background:rgba(0,0,0,0.7);
    }
}

.lil-gui{

    left : 0;
}

</style>
